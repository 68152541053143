import api from "./api";
import config from "./config";

const token = () => {
  return api.post(config.token, {
    telegramData: {
      chat_id: String(window.Telegram.WebApp.initDataUnsafe.chat_instance),
      user_id: String(window.Telegram.WebApp.initDataUnsafe.user.id)
    }
  });
};

const profile = () => {
  return token().then((token)=> {
    api.defaults.headers.Authorization = `Bearer ${token.data.token}`;
    return api.get(config.profile);
  }).catch(()=> {
    window.location = "/";
  })
};

const userProfile = (id) => {
  return token().then((token)=> {
    api.defaults.headers.Authorization = `Bearer ${token.data.token}`;
    return api.get(config.profile + `/${id}`);
  }).catch(()=> {
    window.location = "/";
  })
};

export default {
  profile,
  userProfile
};

import axios from 'axios'
import config from './config'

const api = axios.create({
    timeout: 30000,
    baseURL: config.baseUrl,
    headers: {
        accept: 'application/json'
    }
})
  
export default api
import api from "./api";
import config from "./config";

const token = () => {
  return api.post(config.token, {
    telegramData: {
      chat_id: String(window.Telegram.WebApp.initDataUnsafe.chat_instance),
      user_id: String(window.Telegram.WebApp.initDataUnsafe.user.id)
    }
  });
};

const categoriesList = () => {
  return api.get(config.categoriesList);
};

const categoryUsers = (id) => {
  return token().then((token)=> {
    api.defaults.headers.Authorization = `Bearer ${token.data.token}`;
    return api.get(`/api/v1/category/${id}/get-users`);
  })
};

export default {
  categoriesList,
  categoryUsers
};

import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "index",
      component: () => import("../pages/u-index.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("../pages/u-profile.vue"),
    },
    {
      path: "/categories",
      name: "categories",
      component: () => import("../pages/u-categories.vue"),
    },
    {
      path: "/categories-inner/:id",
      name: "categories-inner",
      component: () => import("../pages/u-categories-inner.vue"),
    },
    {
      path: "/categories-inner/:id/user/:user",
      name: "user",
      component: () => import("../pages/u-user.vue"),
    },
    {
      path: "/edit",
      name: "edit",
      component: () => import("../pages/u-edit.vue"),
    },
    {
      path: "/moderation",
      name: "moderation",
      component: () => import("../pages/u-moderation.vue"),
    },
    // { path: "/:pathMatch(.*)*",
    //   component: () => import("../pages/g-404.vue") ,
    // },
  ],
});

export default router;
